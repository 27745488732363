import React from "react"
import Base from "./Base"
import Banner from "../organisms/banner/Banner"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import Card from "../molecules/Card/Card"
import Content from "../particles/Content"
import { css } from "@emotion/core"
import { randomID } from "../particles/helpers"
import theme from "../particles/Theme"

const archiveContent = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  justify-content: stretch;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
  }
`

const Archive = ({ pageContext }) => {
  return (
    <Base title={pageContext.title}>
      <Banner title={pageContext.title} />
      <Breadcrumbs />
      <Content css={archiveContent}>
        {pageContext.subPages
          .sort((a, b) => (a.title < b.title ? -1 : 1))
          .map(sub => (
            <Card
              key={randomID()}
              title={sub.title}
              link={sub.uri}
              backgroundImage={sub.featuredImage.node || undefined}
            />
          ))}
      </Content>
    </Base>
  )
}

export default Archive
